import { useDidMountEffect } from "hooks/UseDidMountEffect"
import { useState } from "react"
import { useStateValue } from "store/GlobalState"
import SubHeading from "./SubHeading"

//TODO: hide on start will trigger a transition on start
// add error color to it
export default () => {
    const isMobile = window.innerWidth < 768

    const [{growler}, dispatch] = useStateValue()

    const [hide, setHide]= useState<boolean | null>(null)

    const [hideTimer, setHideTimer] = useState<number>(0)

    useDidMountEffect(() => {
        window.setTimeout(() => setHide(false), 500)
        
        const x = window.setTimeout( () => setHide(true), 5500)
        setHideTimer(x)

    }, [growler])

    const hideGrowler = () => {
        setHide(true)
        clearTimeout(hideTimer) 
    } 

    return (
        <div className={`${hide === null? "hidden" : hide ? 'animate-fade-out-up': 'animate-fade-in-down'} ${growler?.type === 'error' ? 'bg-red-500': 'bg-tertiary'} ${isMobile? 'top-1 left-0 w-100 rounded-br-2xl shadow-inner ': 'top-24 mr-6 w-2/6 rounded-2xl '} absolute p-4 right-0 text-white`} style={{zIndex: 12}}>
            <SubHeading>{growler?.title}</SubHeading>
            {growler?.message}
            <div>
                <button className="pt-4 pr-4 text-white font-bold" type="button" onClick={hideGrowler}>Ok</button>
            </div>
        </div>
    )
}