
import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { ContactRequestModel } from '@tabbby/tabbbyapi/Generated/Requests/contact-request-model'

import ApiClient from 'interceptors/ApiClient'

export default {
	sendContact(payload: ContactRequestModel): Promise<AxiosResponse<boolean>> {
		const options: AxiosRequestConfig = { headers: { "Accept-Language": (payload.language === 'nl') ? 'nl-NL': 'en-US' } }
		return ApiClient.post('/contact/send', payload, options)
	}
}