import CheckmarkIcon from "../icons/CheckmarkIcon"

interface CheckmarkListItemProps {
    children: JSX.Element
}

export default ({children} : CheckmarkListItemProps) => {
    return (
        <li className='mb-5 pl-10 relative'>
            <CheckmarkIcon className='absolute inset-y-0 left-0 mt-1'/><span>{children}</span>
        </li>
    )
}