import Modal from "components/Modal"
import SubHeading from "components/SubHeading"
import CloseIcon from 'components/icons/CloseIcon'
import { useStateValue } from 'store/GlobalState'
import { ShowDemoModal } from 'store/GlobalActions'
import DemoForm from "components/DemoForm"
import { useTranslation } from "react-i18next"

export default () => {
    const [{showDemoModal}, dispatch] = useStateValue()
    const {t} = useTranslation()
    const hideModal = () => dispatch(ShowDemoModal(false))

    return (
        <Modal show={showDemoModal} setShow={hideModal}>
             <div className="bg-white rounded-2xl w-96">
                <div className="mx-5 border-b border-standardGrey flex flex-nowrap justify-between content-center items-center">
                    <SubHeading>{t("screens.demoModal.header")}</SubHeading>
                    
                    <div onClick={hideModal} className="cursor-pointer">
                        <CloseIcon />
                    </div>
                </div>
                <p className="font-bold p-5 pb-0">{t("screens.demoModal.footer")}</p>
                <DemoForm />
            </div>
        </Modal>
    )
}