interface TabbbyEggIconProps {
    className?: string
}

export default ({className}: TabbbyEggIconProps) => {
    return (
        <svg className={className} width="108" height="143" viewBox="0 0 108 143" fill="none">
            <g clipPath="url(#clipte1)">
                <path opacity="0.24" d="M67.9512 142.955C90.0426 142.955 107.951 140.269 107.951 136.955C107.951 133.641 90.0426 130.955 67.9512 130.955C45.8598 130.955 27.9512 133.641 27.9512 136.955C27.9512 140.269 45.8598 142.955 67.9512 142.955Z" fill="black"/>
                <path d="M79.0531 0.328779L34.5751 16.5178C31.7391 17.5498 30.2631 20.7158 31.2951 23.5518L37.1601 39.6668L91.9521 19.7238L86.0871 3.60878C85.0551 0.772779 81.8891 -0.703221 79.0531 0.328779ZM45.8611 28.4708C43.9031 29.1838 41.7381 28.1738 41.0251 26.2158C40.3121 24.2578 41.3221 22.0928 43.2801 21.3798C45.2381 20.6668 47.4031 21.6768 48.1161 23.6348C48.8281 25.5928 47.8191 27.7578 45.8611 28.4708Z" fill="#FFCC33"/>
                <path d="M40.4452 48.6919C41.4772 51.5279 44.6422 53.0039 47.4792 51.9719L91.9572 35.7829C94.7932 34.7509 96.2692 31.5849 95.2372 28.7489L94.5332 26.8149L39.7422 46.7579L40.4452 48.6919Z" fill="#FFCC33"/>
                <path d="M46.1781 12.2946L12.6581 24.4946C9.82209 25.5266 8.3461 28.6926 9.3781 31.5286L38.2031 110.726L82.0361 94.7716L53.2121 15.5746C52.1801 12.7386 49.0141 11.2626 46.1781 12.2946ZM45.8611 28.4706C43.9031 29.1836 41.7381 28.1736 41.0251 26.2156C40.3121 24.2576 41.3221 22.0926 43.2801 21.3796C45.2381 20.6666 47.4031 21.6766 48.1161 23.6346C48.8281 25.5926 47.8191 27.7576 45.8611 28.4706Z" fill="#33A4BB"/>
                <path opacity="0.08" d="M53.2117 15.5746L59.0767 31.6896L60.8497 31.0446L54.9847 14.9296C53.9527 12.0936 50.7867 10.6176 47.9507 11.6496L46.1777 12.2946C49.0137 11.2626 52.1797 12.7386 53.2117 15.5746Z" fill="black"/>
                <path opacity="0.08" d="M66.0104 45.2257L63.4297 38.1348L61.6574 38.7798L64.2381 45.8708L66.0104 45.2257Z" fill="black"/>
                <path opacity="0.06" d="M69.1019 59.1288L69.0539 59.0998L68.1579 56.6388L66.0159 63.0708L52.9679 55.0498L48.1299 69.5808L35.0869 61.5578L30.2519 76.0878L24.2539 72.3998L25.2919 75.2508L25.6229 76.1588L31.6199 79.8468L36.4549 65.3168L49.4989 73.3398L54.3359 58.8088L67.3849 66.8298L69.5439 60.3438L69.1019 59.1288Z" fill="black"/>
                <path d="M90.322 45.231L85.274 60.319L72.222 52.299L67.385 66.83L54.336 58.809L49.499 73.34L36.456 65.317L31.621 79.847L18.575 71.825L13.739 86.355L0 77.917C0.558 84.455 1.922 91 4.206 97.276C16.012 129.712 45.19 143.549 71.487 133.978C97.784 124.407 111.242 95.052 99.436 62.616C97.173 56.399 94.051 50.558 90.322 45.231Z" fill="#F6F6F9"/>
            </g>
            <defs>
                <clipPath id="clipte1">
                    <rect width="107.951" height="142.955" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}