import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//import ResetPasswordForm from './components/ResetPasswordForm'
import Footer from 'components/Footer'
//import Nav from 'components/Nav'

export default () => {

	let query = new URLSearchParams(useLocation().search)

	useEffect(() => {
		window.location.href = `tabbby://ResetPassword/${query?.get("hash")}/${query?.get("email")}`
	}, [query])

	return (
		<div>Your will be redirected to reset you password.</div>
	)
/*
	return (
		<div>
			<Nav />
			<ResetPasswordForm email={query?.get("email") || ''} hash={query?.get("hash") || ''} />
			<Footer />
		</div>
	)*/
}