interface ProfileProps {
    url: string
    name: string
    job: string 
    extra?: string
    link: string
}

export default ({url, name, job, extra, link}: ProfileProps) => {
    return(
        <a href={link} target="_blank">
            <div className="flex flex-col justify-center items-center">
                <div className="w-84px h-84px bg-tertiary rounded-full flex justify-center items-center mb-5 relative">
                    <img className="w-78px h-78px rounded-full" src={url} alt={name}/>
                    {extra !== undefined  && <div className="w-36 -right-40 -top-10 absolute rounded-t-lg rounded-br-lg bg-white p-5 hidden md:block">{extra}</div>}
                </div>
                <div className="font-bold text-white">{name}</div>
                <div className="text-white">{job}</div>
            </div>
        </a>
    )
}