import { useTranslation } from "react-i18next"
import CompareTableCell from "./CompareTableCell"
import CompareTableHeader from "./CompareTableHeader"
import CompareTableHeaderText from "./CompareTableHeaderText"

export default () => {
    const {t} = useTranslation()

    return(
            <div className="grid md:grid-cols-compare-table grid-cols-compare-table-mobile bg-white rounded-l-3xl md:rounded-3xl overflow-auto text-table">
                <CompareTableHeader />
                <CompareTableHeader><CompareTableHeaderText title="Tabbby" logo={'tabbby'}/></CompareTableHeader>
                <CompareTableHeader><CompareTableHeaderText title="WieBetaaltWat" logo={'wiebetaaltwat'}/></CompareTableHeader>
                <CompareTableHeader><CompareTableHeaderText title="Splitwise" logo={'splitwise'}/></CompareTableHeader>
                <CompareTableHeader><CompareTableHeaderText title="Potje" logo={'potje'}/></CompareTableHeader>
                <CompareTableHeader><CompareTableHeaderText title="Tikkie/Goepie" logo={'tikkie'}/></CompareTableHeader>
                <CompareTableHeader><CompareTableHeaderText title="Splitt" logo={'splitt'}/></CompareTableHeader>

                <CompareTableCell heading>{t("components.compare.heading1")}</CompareTableCell>
                <CompareTableCell icon/>
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell icon/>
                <CompareTableCell />
                <CompareTableCell />

                <CompareTableCell heading>{t("components.compare.heading2")}</CompareTableCell>
                <CompareTableCell icon/>
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell iconAsterix/>

                <CompareTableCell heading>{t("components.compare.heading3")}</CompareTableCell>
                <CompareTableCell icon/>
                <CompareTableCell>{t("components.compare.diy")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.diy")}</CompareTableCell>
                <CompareTableCell/>
                <CompareTableCell>{t("components.compare.diy")}</CompareTableCell>
                <CompareTableCell iconAsterixx/>

                <CompareTableCell heading>{t("components.compare.heading4")}</CompareTableCell>
                <CompareTableCell icon/>
                <CompareTableCell icon/>
                <CompareTableCell icon/>
                <CompareTableCell/>
                <CompareTableCell icon/>
                <CompareTableCell iconAsterixxx/>

                <CompareTableCell heading>{t("components.compare.heading5")}</CompareTableCell>
                <CompareTableCell icon/>
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell />
                <CompareTableCell icon/>

                <CompareTableCell heading>{t("components.compare.heading6")}</CompareTableCell>
                <CompareTableCell icon/>
                <CompareTableCell icon/>
                <CompareTableCell icon/>
                <CompareTableCell icon/>
                <CompareTableCell icon/>
                <CompareTableCell icon/>

                <CompareTableCell heading>{t("components.compare.heading7")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.free")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.free")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.freemium")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.free")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.free")}</CompareTableCell>
                <CompareTableCell>{t("components.compare.free")}</CompareTableCell>
        </div>
    )
}