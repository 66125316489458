import { GlobalState } from './GlobalStateModel'
import * as fromActionTypes from './GlobalActionTypes'

export default (state: GlobalState, action: fromActionTypes.GlobalActionType): GlobalState => {

	switch (action.type) {
		case fromActionTypes.LOADING:
			return {
				...state,
				loading: action.payload
			}
		case fromActionTypes.REFRESH:
			return {
				...state,
				refresh: state.refresh + 1
			}
		case fromActionTypes.CONTACT_MODEL:
			return {
				...state,
				showContactModal: action.payload
			}
		case fromActionTypes.DEMO_MODEL:
			return {
				...state,
				showDemoModal: action.payload
			}
		case fromActionTypes.PROTOTYPE_MODEL:
				return {
					...state,
					showPrototypeModal: action.payload
				}
		case fromActionTypes.GROWLER: 
			return {
				...state,
				growler: action.payload
			}
		default:
			return state
	}
}