import { FormikProps, ErrorMessage } from 'formik'

type InputType = 'text' | 'password' | 'email'

interface Props {
    inputName: string
    label: string
    formikProps: FormikProps<any>,
    placeholder?: string
    type?: InputType
    required?: boolean
}
export default ({inputName, label, formikProps, placeholder = '', required = false, type = 'text'}: Props) => {
    return (
        <div className="pb-5">
            <label className="block font-bold" htmlFor={inputName}>{label}</label>
            <input 
                className="block p-2 rounded-10 focus:border-quartanary border-2 border-standardGrey w-full" 
                type={type} 
                id={inputName} 
                name={inputName} 
                placeholder={placeholder} 
                required={required}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values[inputName]}
            />
            
            <div className="absolute text-red-500 text-sm">
                { 
                    formikProps.touched && formikProps.errors && formikProps.values[inputName] === ""? 
                    null :
                    <ErrorMessage name={inputName}/>
                }
            </div>
        </div>
    )
}