interface InstructionArrowIconProps {
    pointLeft?: boolean
}

export default ({pointLeft}: InstructionArrowIconProps) => {
    return (
        <svg className={`${pointLeft ? 'transform rotate-180': ''}`} width="42" height="72" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="6.36395" width="9" height="50" rx="4.5" transform="rotate(-45 0 6.36395)" fill="#CECECE"/>
            <rect x="6.36426" y="71.0833" width="9" height="50" rx="4.5" transform="rotate(-135 6.36426 71.0833)" fill="#CECECE"/>
        </svg>
    )
}