import { GrowlerState } from "./GlobalStateModel"

export const REFRESH = "REFRESH"
export const LOADING = "LOADING"

export const CONTACT_MODEL = "CONTACT_MODEL"

export const DEMO_MODEL = "DEMO_MODEL"

export const PROTOTYPE_MODEL = "PROTOTYPE_MODEL"

export const GROWLER = "GROWLER"
interface RefreshAction {
	type: typeof REFRESH
}

interface IsLoadinAction {
	type: typeof LOADING
	payload: boolean
}

interface ShowContactModalAction{
	type: typeof CONTACT_MODEL
	payload: boolean
}

interface ShowDemoModalAction{
	type: typeof DEMO_MODEL
	payload: boolean
}

interface ShowPrototypeModalAction{
	type: typeof PROTOTYPE_MODEL
	payload: boolean
}

interface ShowGrowlerAction {
	type: typeof GROWLER
	payload: GrowlerState
}

export type GlobalActionType = RefreshAction
	| IsLoadinAction
	| ShowContactModalAction
	| ShowDemoModalAction
	| ShowPrototypeModalAction
	| ShowGrowlerAction
	