import React, { useEffect, useState } from "react"
import DesktopNavigation from "./DesktopNavigation"
import MobileNavigation from "./MobileNavigation"
import NavigationJson from "./NavigationJson"

export interface MenuItem {
  title: string
  url: string
}

export default () => {
  
  const [dimensions, setDimensions] = useState<number>(window.innerWidth)

  const handleResize =() => {
    setDimensions(window.innerWidth)
    console.log('resize')
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
        dimensions < 768?
            <MobileNavigation menuItems={NavigationJson}/>
            :
            <DesktopNavigation menuItems={NavigationJson}/>
  )
}