import { ShowDemoModal } from "store/GlobalActions"
import { useStateValue } from "store/GlobalState"
import Button from "./Button"
import { useTranslation } from 'react-i18next'

interface Props {
    className?: string
}

export default ({className}: Props) => {
    const [{}, dispatch] = useStateValue()
    const { t } = useTranslation()

    const openDemoModal = () => dispatch(ShowDemoModal(true))
    
    return(
        <Button className={className || ''} text={t('buttons.demo.title')} preset="primary" onClick={openDemoModal}/>
    )
}