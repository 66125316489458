import ContactForm from "components/ContactForm"
import Modal from "components/Modal"
import SubHeading from "components/SubHeading"
import CloseIcon from 'components/icons/CloseIcon'
import { useStateValue } from 'store/GlobalState'
import { ShowContactModal } from 'store/GlobalActions'
import { useTranslation } from "react-i18next"

export default () => {
    const [{showContactModal}, dispatch] = useStateValue()
    const {t} = useTranslation()
    const hideModal = () => dispatch(ShowContactModal(false))

    return (
        <Modal show={showContactModal} setShow={hideModal}>
             <div className="bg-white rounded-2xl w-96">
                <div className="mx-5 border-b border-gray-400 flex flex-nowrap justify-between content-center items-center">
                    <SubHeading>{t("screens.contactModal.header")}</SubHeading>
                    <div onClick={hideModal} className="cursor-pointer">
                        <CloseIcon />
                    </div>
                </div>
                <ContactForm />
            </div>
        </Modal>
    )
}