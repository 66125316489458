import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AxiosResponse, AxiosError } from 'axios'

import AuthService from 'services/AuthService'

export default () => {
	const [start, setStart] = useState(0)

	const query = new URLSearchParams(useLocation().search)

	const StartElement: JSX.Element = <div>Loading</div>
	const SuccessElement: JSX.Element = <div>Your account is verified {query.get("email")} {query.get("hash")} </div>
	const ErrorElement: JSX.Element = <div>Account not verified {query.get("email")} {query.get("hash")} </div>

	useEffect(() => {
		AuthService.confirmEmail(query.get("email") || "", query.get("hash") || "")
			.then(confirmSuccess)
			.catch(confirmFailed)
	})

	const confirmSuccess = (response: AxiosResponse<boolean>) => {
		console.log(response.data)
		//On success on mobile
		window.location.href = `tabbby://Login/true`
		setStart(1)
	}

	const confirmFailed = (error: AxiosError) => {
		console.log('signup', 'confirm', error)
		setStart(2)
	}

	return (
		<div>
			{
				start === 0 ? StartElement : start === 1 ? SuccessElement : ErrorElement
			}
		</div>
	)
}	