import axios, { AxiosError, AxiosRequestConfig } from 'axios'

const apiClient = axios.create({
  baseURL: 'https://api.tabbby.me',
  //baseURL: 'http://192.168.178.117:5000',
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "nl-NL",
    'Access-Control-Allow-Methods': 'GET,POST'
  }
})

// Add a request interceptor
apiClient.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // const token = await GlobalStorage.loadJWT()
    // if (token) config.headers['Authorization'] = `bearer ${token}`
    config.params = { code: process.env.REACT_APP_APICODE }
    // console.log('------', 'service', API_URL, config.method, config.headers, config.url)
    return Promise.resolve(config)
  },
  (error: AxiosError) => {
    console.log("interceptor error", error)
    Promise.reject(error)
  }
)

export default apiClient