interface Props {
    className?: string
    fillColor?: string
}

export default ({className, fillColor ='#00304F'}: Props) => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M8.91425 7.00001L14.2072 1.70701C14.5982 1.31601 14.5982 0.684006 14.2072 0.293006C13.8162 -0.0979941 13.1842 -0.0979941 12.7933 0.293006L7.50025 5.58601L2.20725 0.293006C1.81625 -0.0979941 1.18425 -0.0979941 0.79325 0.293006C0.40225 0.684006 0.40225 1.31601 0.79325 1.70701L6.08625 7.00001L0.79325 12.293C0.40225 12.684 0.40225 13.316 0.79325 13.707C0.98825 13.902 1.24425 14 1.50025 14C1.75625 14 2.01225 13.902 2.20725 13.707L7.50025 8.41401L12.7933 13.707C12.9882 13.902 13.2443 14 13.5002 14C13.7562 14 14.0122 13.902 14.2072 13.707C14.5982 13.316 14.5982 12.684 14.2072 12.293L8.91425 7.00001Z" fill={fillColor}/>
        </svg>
    )
}