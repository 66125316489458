import DemoButton from "components/buttons/DemoButton"
import Heading from "components/Heading"
import Screen from "components/Screen"
import SubHeading from "components/SubHeading"
import Text from "components/Text"
import { useTranslation } from "react-i18next"

export default () => {
    const { t } = useTranslation()

    return (
        <Screen backgroundType='svg' id="1" paddingOverwrite='pb-0 md:pb-20 md:pt-20 pt-5 px-4'>
            <div className="grid md:grid-cols-3 md:gap-20">
                <div className="md:col-span-2">
                    <Heading inverted>{t("screens.aboutTabbby.header")}</Heading>
                    <Text className='md:hidden' inverted>{t("screens.aboutTabbby.intro")}</Text>
                    <Text className='hidden md:block' inverted bold>{t("screens.aboutTabbby.intro")}</Text>
                    <SubHeading className='hidden md:block' inverted>{t("screens.aboutTabbby.subHeader")}</SubHeading>
                    <Text className='hidden md:block' inverted>{t("screens.aboutTabbby.text")}</Text>
                    <DemoButton className='hidden md:block'/>
                </div>
                <div className="h-96 md:h-auto overflow-hidden md:overflow-visible">
                    <img src="/Tabbby-iPhone-Hero-3.png" className="relative top-10 max-h-hero m-auto filter drop-shadow-2xl"/>
                </div>
            </div>
      </Screen>
    )
}