export default () => {
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" enableBackground="new 0 0 120 120">
            <g id="Background"></g>
            <g id="Layer_2">
                <g>
                    <g>
                        <path fill="#FFFFFF" d="M33,119c-3.859,0-7-3.141-7-7V8c0-3.859,3.141-7,7-7h54c3.859,0,7,3.141,7,7v104c0,3.859-3.141,7-7,7H33z"/>
                        <g>
                            <path fill="#003159" d="M87,2c3.308,0,6,2.692,6,6v104c0,3.308-2.692,6-6,6H33c-3.308,0-6-2.692-6-6V8c0-3.308,2.692-6,6-6H87 M87,0H33c-4.4,0-8,3.6-8,8v104c0,4.4,3.6,8,8,8h54c4.4,0,8-3.6,8-8V8C95,3.6,91.4,0,87,0L87,0z"/>
                        </g>
                    </g>
                    <g>
                        <path fill="#B2E5EB" d="M32,119c-3.309,0-6-2.97-6-6.619V101h68v11.381c0,3.649-2.691,6.619-6,6.619H32z"/>
                        <g>
                            <path fill="#003159" d="M93,102v10.381c0,3.098-2.243,5.619-5,5.619H32c-2.757,0-5-2.521-5-5.619V102H93 M95,100H25v12.381 c0,4.208,3.134,7.619,7,7.619h56c3.866,0,7-3.411,7-7.619V100L95,100z"/>
                        </g>
                    </g>
                    <g>
                        <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="50" y1="11.001" x2="70" y2="11.001"/>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M56.086,43.732c3.337,3.574,5.379,8.371,5.379,13.646c0,5.523-2.239,10.523-5.858,14.142"/>
                                <path fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M48.775,50.556c1.668,1.787,2.689,4.186,2.689,6.823c0,2.761-1.119,5.261-2.929,7.071"/>
                                <path fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M63.396,36.909c5.005,5.36,8.068,12.557,8.068,20.469c0,8.284-3.358,15.784-8.787,21.213"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}