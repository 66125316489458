import Heading from "components/Heading"
import Instructions from "components/instructions/Instructions"
import Screen from "components/Screen"
import PrototypeButton from "components/buttons/PrototypeButton"
import { useTranslation } from "react-i18next"

export default () => {
    const {t} = useTranslation()

    return(
        <Screen backgroundType='light' id="2" paddingOverwrite='px-0 md:py-20 py-5'>
            <div className='px-4'>
                <Heading>{t("screens.howDoesItWork.header")}</Heading>
            </div>
            <Instructions />
            <div className="mt-10 flex flex-col md:flex-row md:justify-center px-4">
                <span className="px-5 py-3 text-center border-2 border-transparent">{t("screens.howDoesItWork.subHeader")}</span>
                <PrototypeButton />
            </div>
        </Screen>
    )
}