import { FormikProps, ErrorMessage } from 'formik'

interface Props {
    inputName: string
    label: string
    formikProps: FormikProps<any>,
    placeholder?: string
    required?: boolean
}
export default ({inputName, label, formikProps, placeholder = '', required = false}: Props) => {
    return (
        <div className="pb-5">
            <label className="block" htmlFor={inputName}>{label}</label>
            <textarea 
                className="block p-2 rounded border border-gray-400 w-full" 
                id={inputName} 
                name={inputName} 
                placeholder={placeholder} 
                required={required}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
            />
            <div className="absolute text-red-500 text-sm">
                <ErrorMessage name={inputName}  />
            </div>
        </div>
    )
}