import { Formik, Form, FormikProps, FormikHelpers } from 'formik'
import { AxiosResponse, AxiosError } from 'axios'

import { ContactRequestModel } from '@tabbby/tabbbyapi/Generated/Requests/contact-request-model'

import Button from "./buttons/Button"
import Input from './forms/Input'
import ContactService from 'services/ContactService'
import { useStateValue } from 'store/GlobalState'
import { ShowDemoModal, ShowGrowler } from 'store/GlobalActions'
import CreateDemoScheme from 'schemes/CreateDemoScheme'
import DemoCreateRequestModel from 'models/DemoCreateRequestModel'
import { useRef, useState } from 'react'
import { GrowlerState } from 'store/GlobalStateModel'
import { useDidMountEffect } from 'hooks/UseDidMountEffect'
import { useTranslation } from 'react-i18next'

export default () => {
    const {t, i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>()
    const [{showDemoModal}, dispatch] = useStateValue()

    const formikRef = useRef<FormikProps<DemoCreateRequestModel>>(null)
    
    const form: DemoCreateRequestModel = {
        name: '',
        email: '',
        company: ''
    }
    
    useDidMountEffect(()=> {
        formikRef.current?.resetForm()
    }, [showDemoModal])

    const onSubmit = (form: DemoCreateRequestModel, helpers: FormikHelpers<DemoCreateRequestModel>) => {    
        const data: ContactRequestModel = {
			email: form.email,
			subject: `Demo via lookbook`,
			message: `company: ${form.company}`,
			sentFrom: 'lookbook',
			userName: form.name,
            subdomain: '',
            language: i18n.language
		}

        setLoading(true)

		ContactService.sendContact(data)
			.then(sendContactSuccess)
            .then(() => resetForm(helpers))
			.catch(sendContactFailed)
            .finally(final)
	}

	const sendContactSuccess = ({ data }: AxiosResponse<boolean>) => {
        dispatch(ShowDemoModal(false))
		dispatch(ShowGrowler({ 
            title:  t("growlers.demo.success.title"), 
            message:  t("growlers.demo.success.message")
        } as GrowlerState))
	}

	const sendContactFailed = (error: AxiosError) => {
        dispatch(ShowDemoModal(false))
        dispatch(ShowGrowler({ 
            title:  t("growlers.demo.error.title"), 
            message: t("growlers.demo.error.message"), 
            type: 'error' 
        } as GrowlerState))
	}

    const resetForm = ({resetForm}: FormikHelpers<DemoCreateRequestModel> ) => {
        resetForm()
    }

    const final = () => {
        setLoading(false)
    }

    return (
        <Formik innerRef={formikRef} initialValues={form} validationSchema={CreateDemoScheme} enableReinitialize isInitialValid={false} onSubmit={onSubmit}>
            {(formikProps: FormikProps<DemoCreateRequestModel>) => (
                <Form noValidate className="p-0">
                    <div className="p-5">
                        <Input inputName="name" label={t("form.labels.name")} placeholder={t("form.placeholders.name")} formikProps={formikProps} required />
                        <Input inputName="company" label={t("form.labels.company")} placeholder={t("form.placeholders.company")} formikProps={formikProps} required />
                        <Input inputName="email" label={t("form.labels.email")} placeholder={t("form.placeholders.email")} type="email" formikProps={formikProps} required />
                    </div>
                    <div className="p-5 border-t border-standardGrey">
                        <Button text={t("form.labels.submit")} block type="submit" loading={loading} disabled={!formikProps.isValid} preset={!formikProps.isValid ? 'disable': 'primary'}/>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
