import { AxiosResponse } from 'axios'
import { ResetPasswordRequestModel } from '@tabbby/tabbbyapi/Generated/Requests/reset-password-request-model'

import ApiClient from 'interceptors/ApiClient'

export default {
	confirmPassword(email: string, code: string): Promise<AxiosResponse<boolean>> {
		var encodedEmail = encodeURIComponent(email).replace('+', '%20')
		return ApiClient.get(`/auth/password/confirm?email=${encodedEmail}&hash=${code}`)
	},
	confirmEmail(email: string, code: string): Promise<AxiosResponse<boolean>> {
		var encodedEmail = encodeURIComponent(email).replace('+', '%20')
		return ApiClient.get(`/auth/signup/confirm?email=${encodedEmail}&hash=${code}`)
	},
	resetPassword(payload: ResetPasswordRequestModel): Promise<AxiosResponse<boolean>> {
		return ApiClient.put('/auth/password/recover', payload)
	}
}