export default () => {
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" enableBackground="new 0 0 120 120">
            <g id="Background"></g>
            <g id="Layer_2">
                <g>
                    <g>
                        <path fill="#FFFFFF" d="M8,94c-3.859,0-7-3.141-7-7V33c0-3.859,3.141-7,7-7h104c3.859,0,7,3.141,7,7v54c0,3.859-3.141,7-7,7H8z"/>
                        <g>
                            <path fill="#003159" d="M112,27c3.308,0,6,2.692,6,6v54c0,3.308-2.692,6-6,6H8c-3.308,0-6-2.692-6-6V33c0-3.308,2.692-6,6-6H112 M112,25H8c-4.4,0-8,3.6-8,8v54c0,4.4,3.6,8,8,8h104c4.4,0,8-3.6,8-8V33C120,28.6,116.4,25,112,25L112,25z"/>
                        </g>
                    </g>
                    <g>
                        <rect x="1" y="41.001" fill="#B2E5EB" width="118" height="18"/>
                        <g>
                            <path fill="#003159" d="M118,42.001v16H2v-16H118 M120,40.001H0v20h120V40.001L120,40.001z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}