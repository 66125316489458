import i18n from 'i18next'
import translationEN from './translation.en.json'
import translationNL from './translation.nl.json'
import { initReactI18next } from "react-i18next"

export const resources = {
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationNL
    }
} as const

i18n.use(initReactI18next).init({
    lng: localStorage.getItem('language') ?? 'nl',
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    resources
})