import { useEffect, useState } from "react"
import InstructionsCarousel from "./InstructionsCarousel"
import InstructionsSlider from "./InstructionsSlider"

export default () => {
  const instructionItems = ["create", "share", "deposit", "pay", "repeat"]

  const [dimensions, setDimensions] = useState<number>(window.innerWidth)

  const handleResize = () => {
    setDimensions(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    dimensions < 768 ?
      <InstructionsCarousel instructionItems={instructionItems} />
      :
      <InstructionsSlider instructionItems={instructionItems} />
  )
}