

export default () => {
    return(
        <svg  viewBox="0 0 308 300" fill="none" xmlns="http://www.w3.org/2000/svg" className='md:w-325px w-200px'>
        <g clipPath="url(#clipBriefcase)">
        <path opacity="0.05" d="M153.85 299.999C238.819 299.999 307.7 293.283 307.7 284.999C307.7 276.715 238.819 269.999 153.85 269.999C68.881 269.999 0 276.715 0 284.999C0 293.283 68.881 299.999 153.85 299.999Z" fill="black"/>
        <path d="M273.227 115H34.2266C29.8086 115 26.2266 119.059 26.2266 124.067V135.002H281.227V124.067C281.227 119.059 277.645 115 273.227 115Z" fill="#534B3F"/>
        <path d="M256.225 190C256.225 194.4 252.625 198 248.225 198H144.225C139.825 198 136.225 194.4 136.225 190V46C136.225 41.6 139.825 38 144.225 38H248.225C252.625 38 256.225 41.6 256.225 46V190Z" fill="white"/>
        <path d="M234.225 102.001H148.225V109.001H234.225V102.001Z" fill="#E9E9EC"/>
        <path d="M214.223 115.001H148.225V122.001H214.223V115.001Z" fill="#E9E9EC"/>
        <path d="M244.225 128.001H148.225V135.001H244.225V128.001Z" fill="#E9E9EC"/>
        <path d="M244.225 141.001H148.225V148.001H244.225V141.001Z" fill="#E9E9EC"/>
        <path d="M244.225 154.001H148.225V161.001H244.225V154.001Z" fill="#E9E9EC"/>
        <path d="M244.225 167.001H148.225V174.001H244.225V167.001Z" fill="#E9E9EC"/>
        <path d="M244.225 180.001H148.225V187.001H244.225V180.001Z" fill="#E9E9EC"/>
        <path d="M160.225 79.94L184.219 62.25" stroke="#244056" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M184.219 62.25L208.741 86.751" stroke="#244056" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M208.74 86.501L232.224 92.001" stroke="#244056" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M160.226 82.9399C161.882 82.9399 163.226 81.5968 163.226 79.9399C163.226 78.2831 161.882 76.9399 160.226 76.9399C158.569 76.9399 157.226 78.2831 157.226 79.9399C157.226 81.5968 158.569 82.9399 160.226 82.9399Z" fill="white"/>
        <path d="M160.225 77.9399C161.328 77.9399 162.225 78.8369 162.225 79.9399C162.225 81.0429 161.328 81.9399 160.225 81.9399C159.122 81.9399 158.225 81.0429 158.225 79.9399C158.225 78.8369 159.122 77.9399 160.225 77.9399ZM160.225 75.9399C158.016 75.9399 156.225 77.7309 156.225 79.9399C156.225 82.1489 158.016 83.9399 160.225 83.9399C162.434 83.9399 164.225 82.1489 164.225 79.9399C164.225 77.7309 162.434 75.9399 160.225 75.9399Z" fill="#244056"/>
        <path d="M184.22 65.25C185.877 65.25 187.22 63.9069 187.22 62.25C187.22 60.5931 185.877 59.25 184.22 59.25C182.563 59.25 181.22 60.5931 181.22 62.25C181.22 63.9069 182.563 65.25 184.22 65.25Z" fill="white"/>
        <path d="M184.219 60.25C185.322 60.25 186.219 61.147 186.219 62.25C186.219 63.353 185.322 64.25 184.219 64.25C183.116 64.25 182.219 63.353 182.219 62.25C182.219 61.147 183.117 60.25 184.219 60.25ZM184.219 58.25C182.01 58.25 180.219 60.041 180.219 62.25C180.219 64.459 182.01 66.25 184.219 66.25C186.428 66.25 188.219 64.459 188.219 62.25C188.219 60.041 186.429 58.25 184.219 58.25Z" fill="#244056"/>
        <path d="M232.226 95C233.882 95 235.226 93.6569 235.226 92C235.226 90.3431 233.882 89 232.226 89C230.569 89 229.226 90.3431 229.226 92C229.226 93.6569 230.569 95 232.226 95Z" fill="white"/>
        <path d="M232.225 90.001C233.328 90.001 234.225 90.898 234.225 92.001C234.225 93.104 233.328 94.001 232.225 94.001C231.122 94.001 230.225 93.104 230.225 92.001C230.225 90.898 231.122 90.001 232.225 90.001ZM232.225 88.001C230.016 88.001 228.225 89.792 228.225 92.001C228.225 94.21 230.016 96.001 232.225 96.001C234.434 96.001 236.225 94.21 236.225 92.001C236.225 89.792 234.434 88.001 232.225 88.001Z" fill="#244056"/>
        <path d="M208.739 89.501C210.396 89.501 211.739 88.1578 211.739 86.501C211.739 84.8441 210.396 83.501 208.739 83.501C207.082 83.501 205.739 84.8441 205.739 86.501C205.739 88.1578 207.082 89.501 208.739 89.501Z" fill="white"/>
        <path d="M208.74 84.501C209.843 84.501 210.74 85.398 210.74 86.501C210.74 87.604 209.843 88.501 208.74 88.501C207.637 88.501 206.74 87.604 206.74 86.501C206.74 85.398 207.637 84.501 208.74 84.501ZM208.74 82.501C206.531 82.501 204.74 84.292 204.74 86.501C204.74 88.71 206.531 90.501 208.74 90.501C210.949 90.501 212.74 88.71 212.74 86.501C212.74 84.292 210.949 82.501 208.74 82.501Z" fill="#244056"/>
        <path d="M160.225 65.939L184.219 92.001" stroke="#3EBDCE" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M184.219 92.001L208.741 70.5" stroke="#3EBDCE" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M208.74 70.5L232.224 54.001" stroke="#3EBDCE" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M160.226 68.939C161.882 68.939 163.226 67.5958 163.226 65.939C163.226 64.2821 161.882 62.939 160.226 62.939C158.569 62.939 157.226 64.2821 157.226 65.939C157.226 67.5958 158.569 68.939 160.226 68.939Z" fill="white"/>
        <path d="M160.225 63.939C161.328 63.939 162.225 64.836 162.225 65.939C162.225 67.042 161.328 67.939 160.225 67.939C159.122 67.939 158.225 67.042 158.225 65.939C158.225 64.836 159.122 63.939 160.225 63.939ZM160.225 61.939C158.016 61.939 156.225 63.73 156.225 65.939C156.225 68.148 158.016 69.939 160.225 69.939C162.434 69.939 164.225 68.148 164.225 65.939C164.225 63.73 162.434 61.939 160.225 61.939Z" fill="#3EBDCE"/>
        <path d="M184.22 95C185.877 95 187.22 93.6569 187.22 92C187.22 90.3431 185.877 89 184.22 89C182.563 89 181.22 90.3431 181.22 92C181.22 93.6569 182.563 95 184.22 95Z" fill="white"/>
        <path d="M184.219 90.001C185.322 90.001 186.219 90.898 186.219 92.001C186.219 93.104 185.322 94.001 184.219 94.001C183.116 94.001 182.219 93.104 182.219 92.001C182.219 90.898 183.117 90.001 184.219 90.001ZM184.219 88.001C182.01 88.001 180.219 89.792 180.219 92.001C180.219 94.21 182.01 96.001 184.219 96.001C186.428 96.001 188.219 94.21 188.219 92.001C188.219 89.792 186.429 88.001 184.219 88.001Z" fill="#3EBDCE"/>
        <path d="M232.226 57C233.882 57 235.226 55.6569 235.226 54C235.226 52.3431 233.882 51 232.226 51C230.569 51 229.226 52.3431 229.226 54C229.226 55.6569 230.569 57 232.226 57Z" fill="white"/>
        <path d="M232.225 52.001C233.328 52.001 234.225 52.898 234.225 54.001C234.225 55.104 233.328 56.001 232.225 56.001C231.122 56.001 230.225 55.104 230.225 54.001C230.225 52.898 231.122 52.001 232.225 52.001ZM232.225 50.001C230.016 50.001 228.225 51.792 228.225 54.001C228.225 56.21 230.016 58.001 232.225 58.001C234.434 58.001 236.225 56.21 236.225 54.001C236.225 51.792 234.434 50.001 232.225 50.001Z" fill="#3EBDCE"/>
        <path d="M208.739 73.5C210.396 73.5 211.739 72.1569 211.739 70.5C211.739 68.8431 210.396 67.5 208.739 67.5C207.082 67.5 205.739 68.8431 205.739 70.5C205.739 72.1569 207.082 73.5 208.739 73.5Z" fill="white"/>
        <path d="M208.74 68.5C209.843 68.5 210.74 69.397 210.74 70.5C210.74 71.603 209.843 72.5 208.74 72.5C207.637 72.5 206.74 71.603 206.74 70.5C206.74 69.397 207.637 68.5 208.74 68.5ZM208.74 66.5C206.531 66.5 204.74 68.291 204.74 70.5C204.74 72.709 206.531 74.5 208.74 74.5C210.949 74.5 212.74 72.709 212.74 70.5C212.74 68.291 210.949 66.5 208.74 66.5Z" fill="#3EBDCE"/>
        <path d="M170.851 158C170.851 162.4 167.251 166 162.851 166H58.8506C54.4506 166 50.8506 162.4 50.8506 158V14C50.8506 9.6 54.4506 6 58.8506 6H162.851C167.251 6 170.851 9.6 170.851 14V158Z" fill="white"/>
        <path d="M138.85 18H62.8506V25H138.85V18Z" fill="#E9E9EC"/>
        <path d="M158.851 31H62.8506V38H158.851V31Z" fill="#E9E9EC"/>
        <path d="M128.849 44H62.8506V51H128.849V44Z" fill="#E9E9EC"/>
        <path d="M138.85 57H62.8506V64H138.85V57Z" fill="#E9E9EC"/>
        <path d="M158.851 70H62.8506V77H158.851V70Z" fill="#E9E9EC"/>
        <path d="M118.849 83H62.8506V90H118.849V83Z" fill="#E9E9EC"/>
        <path d="M148.851 96H62.8506V103H148.851V96Z" fill="#E9E9EC"/>
        <path d="M128.849 109H62.8506V116H128.849V109Z" fill="#E9E9EC"/>
        <path d="M138.85 122H62.8506V129H138.85V122Z" fill="#E9E9EC"/>
        <path d="M158.851 135H62.8506V142H158.851V135Z" fill="#E9E9EC"/>
        <path d="M148.851 148H62.8506V155H148.851V148Z" fill="#E9E9EC"/>
        <path d="M150.85 9.99902C149.196 9.99902 147.85 11.345 147.85 12.999V22.999C147.85 23.552 148.297 23.999 148.85 23.999C149.403 23.999 149.85 23.552 149.85 22.999V12.999C149.85 12.447 150.298 11.999 150.85 11.999C151.402 11.999 151.85 12.447 151.85 12.999V24.999C151.85 26.653 150.504 27.999 148.85 27.999C147.196 27.999 145.85 26.653 145.85 24.999V8.99902C145.85 7.34502 147.196 5.99902 148.85 5.99902H152.824C151.91 4.79202 150.476 3.99902 148.85 3.99902C146.093 3.99902 143.85 6.24202 143.85 8.99902V24.999C143.85 27.756 146.093 29.999 148.85 29.999C151.607 29.999 153.85 27.756 153.85 24.999V12.999C153.85 11.345 152.505 9.99902 150.85 9.99902Z" fill="#244056"/>
        <path d="M83.2803 72.192C86.0663 71.177 89.1763 72.628 90.1903 75.416L95.9543 91.255L126.676 80.068L120.913 64.228C119.899 61.44 116.789 59.99 114.002 61.004L83.2803 72.192Z" fill="#FFCC33"/>
        <path d="M129.903 88.9379L129.212 87.0369L98.4902 98.2239L101.026 105.193L126.681 95.8509C129.467 94.8369 130.918 91.7259 129.903 88.9379Z" fill="#FFCC33"/>
        <path d="M83.2806 72.192L48.7736 84.757C45.9866 85.772 44.5366 88.883 45.5516 91.67L73.8776 169.51L91.8706 150.164L118.518 153.255L90.1916 75.415C89.1766 72.628 86.0666 71.178 83.2806 72.192ZM82.9686 88.091C81.0446 88.791 78.9176 87.799 78.2176 85.875C77.5176 83.95 78.5096 81.822 80.4326 81.122C82.3556 80.422 84.4836 81.414 85.1836 83.338C85.8836 85.263 84.8926 87.391 82.9686 88.091Z" fill="#3EBDCE"/>
        <path d="M83.2806 72.1919L48.7736 84.757C45.9866 85.772 44.5366 88.8829 45.5516 91.6699L73.8776 169.51L99.3156 160.248L118.518 153.256L90.1916 75.4159C89.1766 72.6279 86.0666 71.1779 83.2806 72.1919ZM82.9686 88.091C81.0446 88.791 78.9176 87.7989 78.2176 85.8749C77.5176 83.9499 78.5096 81.8219 80.4326 81.1219C82.3556 80.4219 84.4836 81.414 85.1836 83.338C85.8836 85.263 84.8926 87.391 82.9686 88.091Z" fill="#3EBDCE"/>
        <path d="M81.7002 88.3149C83.7481 88.3149 85.4082 86.6548 85.4082 84.6069C85.4082 82.559 83.7481 80.8989 81.7002 80.8989C79.6523 80.8989 77.9922 82.559 77.9922 84.6069C77.9922 86.6548 79.6523 88.3149 81.7002 88.3149Z" fill="#244056"/>
        <path d="M281.227 135.001H26.2266V268.999C26.2266 277.799 33.4266 284.999 42.2266 284.999H265.227C274.027 284.999 281.227 277.799 281.227 268.999V135.001Z" fill="#A5967E"/>
        <path d="M26.2266 210.501H281.227" stroke="#655E52" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M66.2285 218.501C66.2285 221.251 63.9785 223.501 61.2285 223.501C58.4785 223.501 56.2285 221.251 56.2285 218.501V202.501C56.2285 199.751 58.4785 197.501 61.2285 197.501C63.9785 197.501 66.2285 199.751 66.2285 202.501V218.501Z" fill="#655E52"/>
        <path d="M255.225 218.501C255.225 221.251 252.975 223.501 250.225 223.501C247.475 223.501 245.225 221.251 245.225 218.501V202.501C245.225 199.751 247.475 197.501 250.225 197.501C252.975 197.501 255.225 199.751 255.225 202.501V218.501Z" fill="#655E52"/>
        </g>
        <defs>
        <clipPath id="clipBriefcase">
        <rect width="307.7" height="299.999" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
   
    )
}