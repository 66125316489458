type BackgroundType = 'light' | 'dark' | 'svg' | 'white'
interface ScreenProps {
    backgroundType: BackgroundType
    children: JSX.Element | JSX.Element[]
    id?: string
    paddingOverwrite?: string
}

export default ({ backgroundType, children, id, paddingOverwrite="md:py-20 py-5 px-4"}: ScreenProps) => {

    const bgSvg = `bg-secondary md:bg-round md:bg-no-repeat md:bg-auto md:bg-top md:bg-transparent`

    return (
        <div id={id} className={`${backgroundType === 'white' ? 'bg-white' : backgroundType === 'dark' ? 'bg-secondary' : backgroundType === 'svg' ? bgSvg: ''}`}>
            <div className={`container mx-auto ${paddingOverwrite}`}>
                {Array.isArray(children)? children?.map((item, key)=> (<div key={key}>{item}</div>)): children}
            </div>
        </div>
    )
}