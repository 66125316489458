interface CheckmarkListProps {
    children: JSX.Element | JSX.Element[]
}

export default ({children} : CheckmarkListProps) => { 
    return (
        <ul>
          {children}
        </ul>
    )
}