import DemoButton from "components/buttons/DemoButton"
import Language from "components/buttons/Language"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CloseIcon from "../icons/CloseIcon"

import HamburgerIcon from "../icons/HamburgerIcon"
import LogoHeader from "../icons/LogoHeader"
import { MenuItem } from "./Navigation"

interface MobileNavigationProp {
    menuItems: MenuItem[]
}

export default ({menuItems}: MobileNavigationProp) => {
    const {t} = useTranslation()
    const [menuIsOpen, setMenuOpen] = useState(false)
    const [visible, setVisible] = useState(true)
    const [scrollPosition, setScrollPosition] = useState(window.pageYOffset)

    const handleScroll = (e: Event) => {
        console.log('handlescroll')
        setVisible(scrollPosition > window.pageYOffset)
        setScrollPosition(window.pageYOffset)
      }

    useEffect(() => {
        console.log('test', scrollPosition)
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll)
    })

    

    const menu = menuItems.map((item, key) => {
        console.log("map")
        return <div key={key} className="border-b font-bold "><a className="py-5 flex" href={item.url} onClick={() => setMenuOpen(false)}>{t(`navigation.${item.title}`)}</a></div>   
    })

    return (
        <>
            <div className={`sticky top-0 h-16 bg-white w-full flex z-10 shadow ${visible? 'vtom' : 'h-tom'}`}>
                <div className="flex container mx-auto items-center justify-between px-4">
                    <div className="flex items-center">
                        <LogoHeader height='40px'/>
                    </div>
                    <div className="flex items-center self-stretch">
                        <div onClick={() => setMenuOpen(true)}>
                            <HamburgerIcon />
                        </div>
                    </div>
                </div>
                
            </div>
            <div className={`fixed inset-0 bg-secondary z-30 opacity-80 ${menuIsOpen? '': 'hidden'}`} onClick={() => setMenuOpen(false)}></div>
            <div className={`z-30 fixed inset-y-0 right-0 h-full w-10/12 bg-white p-5 flex flex-col ${menuIsOpen? '': 'hidden'}`}>
                <div className="flex-grow">
                    <div className=" border-b pb-5 flex justify-between items-center">
                        <div className="text-tertiary text-2xl font-heading">Menu</div>
                       
                        <div onClick={() => setMenuOpen(false)}><CloseIcon /></div>
                    </div>
                   {menu}
                    <div className="py-5 flex">
                        <Language/>
                    </div>
                </div>
                <DemoButton className='w-full'/>
            </div>
        </>
    )
}