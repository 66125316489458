import Heading from "components/Heading"
import Screen from "components/Screen"
import Text from "components/Text"
import CheckmarkList from "components/checkmarkList/CheckmarkList"
import CheckmarkListItem from "components/checkmarkList/CheckmarkListItem"
import SubHeading from "components/SubHeading"
import Briefcase from "components/icons/Briefcase"
import { Trans, useTranslation } from "react-i18next"

export default () => {
    const {t} = useTranslation()
    
    return (
        <Screen backgroundType='light' id="4">
            <div className="grid md:grid-cols-2 md:gap-20">
                <div>
                    <Heading>{t("screens.development.header")}</Heading>
                    <Text bold>{t("screens.development.intro")}</Text>
                    <SubHeading >{t("screens.development.subHeader")}</SubHeading>
                    <CheckmarkList>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark1")}</span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span><Trans>{t("screens.development.checkmark2")}</Trans></span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark3")}</span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark4")}</span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark5")}</span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark6")}</span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark7")}</span>
                        </CheckmarkListItem>
                        <CheckmarkListItem>
                            <span>{t("screens.development.checkmark8")}</span>
                        </CheckmarkListItem>
                    </CheckmarkList>
                </div>
                <div className="flex items-center justify-center">
                    <Briefcase />
                </div>
            </div>
      </Screen>
    )
}