import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { StateProvider } from "store/GlobalState"

import ReactGA from 'react-ga4'
import "i18n/config"
import Home from 'pages/home/Home'
import Loading from 'components/Loading'
import Deposit from 'pages/deposit/Deposit'
import Share from 'pages/share/Share'
import Reset from 'pages/password/Reset'
import Confirm from 'pages/signup/Confirm'

const TRACKING_ID = "G-Z121VGE1YT" // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

export default () => (
  <StateProvider>
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/share/:hash" component={Share} />
					<Route path="/password/reset" component={Reset} />
					{/* <Route path="/password/resetConfirm" component={ResetConfirm} /> */}
					<Route path="/deposit" component={Deposit} />

					<Route path="/signup/confirm" component={Confirm} />
        </Switch>
      </Router>
    </Suspense>
  </StateProvider>
)

