export default () => {
    return(
            
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="140px" height="120px" viewBox="0 0 140 120" enableBackground="new 0 0 140 120">
            <g id="Background"></g>
            <g id="Layer_2">
                <g>
                    <path fill="#FFFFFF" d="M8,94c-3.859,0-7-3.141-7-7V33c0-3.859,3.141-7,7-7h104c3.859,0,7,3.141,7,7v54c0,3.859-3.141,7-7,7H8z"/>
                    <g>
                        <path fill="#003159" d="M112,27c3.308,0,6,2.692,6,6v54c0,3.308-2.692,6-6,6H8c-3.308,0-6-2.692-6-6V33c0-3.308,2.692-6,6-6H112 M112,25H8c-4.4,0-8,3.6-8,8v54c0,4.4,3.6,8,8,8h104c4.4,0,8-3.6,8-8V33C120,28.6,116.4,25,112,25L112,25z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill="#B2E5EB" d="M120,113.003c-10.477,0-19-8.523-19-19s8.523-19,19-19s19,8.523,19,19S130.477,113.003,120,113.003z"/>
                        <g>
                            <path fill="#003159" d="M120,76.003c9.925,0,18,8.075,18,18s-8.075,18-18,18s-18-8.075-18-18S110.075,76.003,120,76.003 M120,74.003c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20s20-8.954,20-20C140,82.957,131.046,74.003,120,74.003 L120,74.003z"/>
                        </g>
                    </g>
                    <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="111" y1="94.003" x2="129" y2="94.003"/>
                    <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="120" y1="85.003" x2="120" y2="103.003"/>
                </g>
            </g>
        </svg>
    )
}