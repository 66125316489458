export default () => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" enableBackground="new 0 0 120 120">
            <g id="Background"></g>
            <g id="Layer_2">
                <g>
                    <g>
                        <path fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M76.211,87.501c-4.751,2.807-10.293,4.417-16.211,4.417c-17.629,0-31.92-14.291-31.92-31.92c0-5.919,1.611-11.462,4.419-16.213"/>
                        <path fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M87.502,76.211c2.807-4.751,4.417-10.293,4.417-16.211c0-17.629-14.291-31.92-31.92-31.92c-5.919,0-11.462,1.611-16.213,4.419"/>
                        <g>
                            <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="43.786" y1="32.498" x2="46.515" y2="25"/>
                            <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="43.786" y1="32.498" x2="51.284" y2="35.227"/>
                        </g>
                        <g>
                            <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="68.713" y1="84.772" x2="76.211" y2="87.501"/>
                            <line fill="none" stroke="#003159" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="73.482" y1="95" x2="76.211" y2="87.501"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}