import Heading from "components/Heading"
import Screen from "components/Screen"
import Text from "components/Text"
import SubHeading from "components/SubHeading"
import TabbbyEggIcon from "components/icons/TabbbyEggIcon"
import Profile from "components/Profile"
import { useTranslation } from "react-i18next"

export default () => {
    const {t} = useTranslation()

    return(
        <Screen backgroundType='dark' id="5">
            <Heading inverted>{t("screens.aboutUs.header")}</Heading>
            <div className="grid md:grid-cols-2 md:gap-20 mb-10">
                <div>
                    <SubHeading inverted>{t("screens.aboutUs.subHeader")}</SubHeading>
                    <Text inverted bold>{t("screens.aboutUs.intro")}</Text>
                    <Text inverted>{t("screens.aboutUs.text")}</Text>
                    <TabbbyEggIcon className="ml-10 mb-10"/>
                    <Text inverted>{t("screens.aboutUs.text2")}</Text>
                </div>
                <div>
                <SubHeading inverted>{t("screens.team.header")}</SubHeading>
                    <Text inverted>{t("screens.team.intro")}</Text>
                    <div className="grid grid-cols-2 gap-10">
                        <Profile name="Ramon Klanke" job= "Founder/'Back'-end" url="/images/ray.png" link="https://linkedin.com/in/ramonklanke"/>
                        <Profile name="Jay Huliselan" job= "UX/UI" url="/images/jay.png" link="https://linkedin.com/in/jayhuliselan"/>
                        <Profile name="Bart Snoek" job= "UX/UI" url="/images/bart.png" link="https://linkedin.com/in/bamsnoek"/>
                    </div>
                </div>
            </div>
        </Screen>
    )
}