import { Formik, Form, FormikProps } from 'formik'
import {AxiosResponse, AxiosError} from 'axios'
import ContactCreateRequestModel from 'models/ContactCreateRequestModel'

import { ContactRequestModel } from '@tabbby/tabbbyapi/Generated/Requests/contact-request-model'

import CreateContactScheme from 'schemes/CreateContactScheme'

import Button from "./buttons/Button"
import Input from './forms/Input'
import ContactService from 'services/ContactService'
import { useStateValue } from 'store/GlobalState'
import { ShowContactModal } from 'store/GlobalActions'
import TextArea from './forms/TextArea'
import { useTranslation } from 'react-i18next'

export default () => {
    const {i18n} = useTranslation()
    const [{}, dispatch] = useStateValue()

    const form: ContactCreateRequestModel = {
        name: '',
        email: '',
        message: ''
    }

    const onSubmit = (form: ContactCreateRequestModel) => {
        console.log(form)
    
        const data: ContactRequestModel = {
			email: form.email,
			subject: 'Contact via lookbook',
			message: `${form.message}`,
			sentFrom: 'lookbook',
			userName: form.name,
            subdomain: '',
            language: i18n.language
		}

		//setLoading(true)

		ContactService.sendContact(data)
			.then(sendContactSuccess)
			.catch(sendContactFailed)
            .finally(final)
	}

	const sendContactSuccess = ({ data }: AxiosResponse<boolean>) => {
		console.log('success', data)
		//dispatch(ShowGrowler({ title: "Demo aanvraag verstuurd", message: "We nemen zo snel mogelijk contact met je op!" } as ToasterState))
		//navigation.navigate("Settings")
        dispatch(ShowContactModal(false))
	}

	const sendContactFailed = (error: AxiosError) => {
		//dispatch(AddToaster({ title: "notify.contact.error.title", message: "notify.contact.error.message", type: "error" } as ToasterState))
		console.log('error', 'sendContact', error)
	}

    const final = () => {
        //setLoading(false)
    }

    return (
        <Formik initialValues={form} validationSchema={CreateContactScheme} isInitialValid={false} enableReinitialize onSubmit={onSubmit}>
            {(formikProps: FormikProps<ContactCreateRequestModel>) => (
                <Form noValidate className="p-0">
                    <div className="p-5">
                        <Input inputName="name" label="Naam" formikProps={formikProps} required />
                        <Input inputName="email" label="E-mailadres" placeholder="Vul uw bedrijfsnaam in" type="email" formikProps={formikProps} required />
                        <TextArea inputName="message" label="Bericht" formikProps={formikProps} required />
                    </div>
                    <div className="p-5 border-t border-gray-400">
                        <Button text="Verstuur aanvraag" block type="submit" disabled={!formikProps.isValid} />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
