import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import InstructionArrowIcon from "../icons/InstructionArrowIcon"
import InstructionsItem from "./InstructionsItem"

interface InstructionsSliderProps {
    instructionItems: string[]
}

export default ({instructionItems}: InstructionsSliderProps) => {
    const {t} = useTranslation()

    const sliderEl = useRef<HTMLDivElement>(null)

    const slideLeft = () => {
        if(sliderEl.current) {
            let ref = sliderEl.current

            let scrollAmount = 0;
            let slideTimer = setInterval(function(){
                ref.scrollLeft -= 20
                scrollAmount += 20
                if(scrollAmount >= 250){
                    window.clearInterval(slideTimer)
                }
            }, 25)
        }
    }
    
    const slideRight= () => {
        if(sliderEl.current) {
            let ref = sliderEl.current

            let scrollAmount = 0
            let slideTimer = setInterval(function(){
                ref.scrollLeft += 20
                scrollAmount += 20
                if(scrollAmount >= 250){
                    window.clearInterval(slideTimer)
                }
            }, 25)
        }
    }

    const slider = instructionItems.map((item, key) => (
            <div key={key} className="w-80 mx-5 flex-none">
                <InstructionsItem title={t(`components.instructions.${item}.title`)} url={item}>
                   {t(`components.instructions.${item}.body`)}
                </InstructionsItem>
            </div>
        )
    )

    return (
        <div className="relative overflow-visible">
            <div ref={sliderEl} className="flex overflow-x-scroll -my-2 py-2">
                <span onMouseDown={slideLeft} className="-left-11 absolute h-44 flex items-center opacity-50 hover:bg-gray-200 hover:opacity-100"><InstructionArrowIcon pointLeft/></span>
                {slider}
                <span onMouseDown={slideRight} className="absolute -right-11 h-44 items-center flex opacity-50 hover:bg-gray-200 hover:opacity-100"><InstructionArrowIcon/></span>
            </div>
        </div>
    )
}