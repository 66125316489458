import { useTranslation } from "react-i18next"
import { ShowPrototypeModal } from "store/GlobalActions"
import { useStateValue } from "store/GlobalState"
import Button from "./Button"

interface Props {
    className?: string
}

export default ({className}: Props) => {
    const [{}, dispatch] = useStateValue()
    const {t} = useTranslation()
    
    const openPrototypeModal = () => dispatch(ShowPrototypeModal(true))
    
    return(
        <Button className={className || ''} text={t("buttons.prototype.title")} preset="ghost" onClick={openPrototypeModal}/>
    )
}