export default () => {
    return(
            
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" enableBackground="new 0 0 120 120">
            <g id="Background"></g>
            <g id="Layer_2">
                <g>
                    <line fill="none" stroke="#003159" strokeWidth="2" strokeMiterlimit="10" x1="38.295" y1="60" x2="79.298" y2="34.998"/>
                    <line fill="none" stroke="#003159" strokeWidth="2" strokeMiterlimit="10" x1="38.295" y1="60" x2="79.296" y2="85.002"/>
                    <g>
                        <g>
                            <path fill="#FFFFFF" d="M38.295,69c-4.963,0-9-4.037-9-9s4.037-9,9-9s9,4.037,9,9S43.258,69,38.295,69z"/>
                            <g>
                                <path fill="#003159" d="M38.295,52c4.411,0,8,3.589,8,8s-3.589,8-8,8s-8-3.589-8-8S33.884,52,38.295,52 M38.295,50 c-5.523,0-10,4.477-10,10s4.477,10,10,10s10-4.477,10-10S43.818,50,38.295,50L38.295,50z"/>
                            </g>
                        </g>
                        <g>
                            <path fill="#FFFFFF" d="M79.298,43.999c-4.963,0-9-4.037-9-9s4.037-9,9-9s9,4.037,9,9S84.261,43.999,79.298,43.999z"/>
                            <g>
                                <path fill="#003159" d="M79.298,26.998c4.411,0,8,3.589,8,8s-3.589,8-8,8s-8-3.589-8-8S74.886,26.998,79.298,26.998 M79.298,24.998c-5.523,0-10,4.477-10,10s4.477,10,10,10s10-4.477,10-10S84.821,24.998,79.298,24.998L79.298,24.998z"/>
                            </g>
                        </g>
                        <g>
                            <path fill="#FFFFFF" d="M79.296,94.001c-4.963,0-9-4.037-9-9s4.037-9,9-9s9,4.037,9,9S84.259,94.001,79.296,94.001z"/>
                            <g>
                                <path fill="#003159" d="M79.296,77.002c4.411,0,8,3.589,8,8s-3.589,8-8,8s-8-3.589-8-8S74.885,77.002,79.296,77.002 M79.296,75.002c-5.523,0-10,4.477-10,10s4.477,10,10,10s10-4.477,10-10S84.819,75.002,79.296,75.002L79.296,75.002z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}