export default () => (

    <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path d="M51 92.78C74.0745 92.78 92.78 74.0745 92.78 51C92.78 27.9255 74.0745 9.22 51 9.22C27.9255 9.22 9.22 27.9255 9.22 51C9.22 74.0745 27.9255 92.78 51 92.78Z" fill="#003159" stroke="#3EBDCE" strokeWidth="3.56"/>
            <path opacity="0.08" d="M82.5859 44.2531L81.3098 40.7465L76.7299 28.1635C75.6125 25.0927 72.2045 23.5036 69.1331 24.6211L26.6403 40.0873C23.569 41.2054 21.9805 44.6127 23.0979 47.6841L38.081 88.8502C46.4208 91.702 55.7514 91.8377 64.6787 88.5884C67.7891 87.4563 70.6685 85.9721 73.3002 84.2076L63.5722 57.4806L79.0429 51.8499C82.1143 50.7311 83.7034 47.3238 82.5859 44.2531Z" fill="black"/>
            <path d="M69.9614 26.8898L41.4955 37.2507C39.6804 37.9112 38.7358 39.9375 39.3963 41.7525L43.1499 52.0661L78.2168 39.3026L74.4632 28.989C73.8027 27.1739 71.7764 26.2293 69.9614 26.8898ZM48.7179 44.9C47.4648 45.3563 46.0792 44.7099 45.6228 43.4568C45.1665 42.2037 45.8129 40.8181 47.066 40.3618C48.3192 39.9055 49.7048 40.5519 50.1611 41.805C50.6174 43.0587 49.9716 44.4443 48.7179 44.9Z" fill="#FFCC33"/>
            <path d="M45.2533 57.8426C45.9138 59.6576 47.9394 60.6022 49.7551 59.9418L78.221 49.5808C80.036 48.9203 80.9807 46.8941 80.3202 45.079L79.8696 43.8413L44.8027 56.6048L45.2533 57.8426Z" fill="#FFCC33"/>
            <path opacity="0.08" d="M53.4236 36.6471L57.1772 46.9607L58.312 46.5479L54.5584 36.2343C53.8979 34.4192 51.8716 33.4746 50.0566 34.1351L48.9219 34.5479C50.7369 33.8874 52.7632 34.8314 53.4236 36.6471Z" fill="black"/>
            <path opacity="0.08" d="M61.6145 55.624L59.9629 51.0858L58.8286 51.4986L60.4803 56.0368L61.6145 55.624Z" fill="black"/>
            <path d="M53.4227 36.6472C52.7622 34.8322 50.736 33.8875 48.9209 34.548L27.4681 42.356C25.6531 43.0165 24.7085 45.0427 25.3689 46.8578L40.9689 89.7179C48.5433 91.6878 56.7705 91.4664 64.6809 88.5877C66.9875 87.748 69.1616 86.7093 71.2083 85.5138L53.4227 36.6472ZM48.7174 44.9C47.4643 45.3563 46.0787 44.7099 45.6224 43.4568C45.1661 42.2037 45.8125 40.8181 47.0656 40.3618C48.3187 39.9055 49.7043 40.5519 50.1606 41.805C50.6169 43.0587 49.9712 44.4443 48.7174 44.9Z" fill="#3EBDCE"/>
        </g>
        <defs>
            <filter id="filter0_d" x="0.319453" y="0.320003" width="101.36" height="101.36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="3.56"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
        </defs>
    </svg>
)