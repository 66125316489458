type Preset = 'primary' | 'ghost' | 'disable'

interface Props extends React.ComponentPropsWithoutRef<"button">  {
    text: string
    preset?: Preset
    block?: boolean
    loading?: boolean
    className?: string
    additionalStyles?: string
}

export default ({ text, preset = 'primary', block = false, disabled = false, loading= false, type = 'button', className, ...props}: Props) => {
    const primary = 'bg-primary rounded-10 text-secondary shadow-button hover:shadow-buttonHover transform focus:translate-y-1 hover:-translate-y-1 focus:shadow-none duration-150'
    const ghost = 'bg-transparent rounded-10 border-2 border-secondary hover:shadow-secButtonHover transform focus:translate-y-0 hover:-translate-y-1 focus:shadow-none duration-150'
    const disable = 'bg-disabledGrey text-darkGrey rounded-10 pointer-events-none'
    
    const buttonType = () => { 
        switch(preset) {
            case 'primary':
                return primary
            case 'ghost': 
                return ghost
            case 'disable':
                return disable
            default:
                return primary
        }
    }

    return (
        <button 
            type={type} 
            className={`${className || ''} font-bold px-10 py-3 relative ${buttonType()}  ${block ? 'w-full': ''}`} 
            disabled={disabled}
            {...props}
            >
                {text}
                <div className={`${loading? '': 'hidden'} absolute right-4 top-4 inline-block animate-spin rounded-full h-4 w-4`} style={{border: '2px solid gray', borderBottomColor: '#003159' }}></div>
        </button>
        )
}