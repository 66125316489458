export default [
    {
        title: "about",
        url: "#1",
    },
    {
        title: "how",
        url: "#2",
    },
    {
        title: "why",
        url: "#3",
    },
    {
        title: "development",
        url: "#4",
    },
    {
        title: "team",
        url: "#5",
    },
]
