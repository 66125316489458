interface CheckmarkIconProps {
    className?: string
    width?: string
    height?: string
    fillColor?: string
}

export default ({className, width='16', height='16', fillColor='#3ABACC'}: CheckmarkIconProps) => {
    return(
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.06299L3.7586 11.6106C3.8514 11.7299 3.96979 11.8268 4.10505 11.8942C4.2403 11.9615 4.38896 11.9977 4.54005 11.9999C4.69114 12.0021 4.8408 11.9704 4.97799 11.907C5.11517 11.8437 5.23637 11.7503 5.33264 11.6338L14.117 1" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}