import { useTranslation } from 'react-i18next'

export default () => {
    const { i18n } = useTranslation()
    
    const changeLanguage = (lang: string) => {
        //const lang = i18n.language  === 'en' ? 'nl': 'en'
        i18n.changeLanguage(lang)
        localStorage.setItem('language', lang)
      }

    return (
        <div className="overflow-hidden whitespace-nowrap">
            <div className={`inline-block px-2 rounded-l-full border font-bold ${i18n.language == 'nl' ? 'bg-tertiary border-tertiary text-white cursor-default' : 'border-gray-400 border-r-0 cursor-pointer' }`} onClick={() => changeLanguage("nl")}>NL</div>
            <div className={`inline-block px-2 rounded-r-full border font-bold ${i18n.language == 'en' ? 'bg-tertiary border-tertiary text-white cursor-default' : 'border-gray-400 border-l-0 cursor-pointer' }`} onClick={() => changeLanguage("en")}>EN</div>
        </div>
    )
}