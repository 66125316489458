import { useTranslation } from 'react-i18next'
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import InstructionsItem from "./InstructionsItem"

interface InstructionsCarouselProps {
    instructionItems: string[]
}

export default ({instructionItems}: InstructionsCarouselProps) => {
    const {t} = useTranslation()

    const instructions = instructionItems.map((item, key) => {
        return (
            <InstructionsItem key={key} title={t(`components.instructions.${item}.title`)} url={item}>
                {t(`components.instructions.${item}.body`)}
            </InstructionsItem>
        )
    })

    return (
        <Carousel 
        showThumbs={false} 
        autoPlay={false}
        swipeable={true}
        showArrows={false}
        showStatus={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
                return (
                    <li
                        key={index}
                        className="h-2.5 w-2.5 mx-2  inline-block rounded-full border-2 border-tertiary bg-tertiary"
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                    />
                )
            }
            return (
                <li
                    className="h-2.5 w-2.5 mx-2 inline-block rounded-full border-2 border-tertiary"
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                />
            )
        }}>
                {instructions}
            </Carousel>
    )
}