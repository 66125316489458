import CompareTable from "components/compareTable/CompareTable"
import Heading from "components/Heading"
import Screen from "components/Screen"
import Text from "components/Text"
import { useTranslation } from "react-i18next"

export default () => {
	const {t} = useTranslation()
	
    return(
      <Screen backgroundType='dark' id="3" paddingOverwrite="md:py-20 md:pr-4 py-5 pl-4 pr-0">
        <div className="grid md:grid-cols-2 pr-4">
            <div>
                <Heading inverted>{t("screens.whyTabbby.header")}</Heading>
                <Text bold inverted>{t("screens.whyTabbby.intro")}</Text>
           </div>
          </div>
                <CompareTable />
          <div className="pr-4">
                <div className="text-white text-xs italic mt-2">{t("screens.whyTabbby.footnote1")}</div>
                <div className="text-white text-xs italic mt-2">{t("screens.whyTabbby.footnote2")}</div>
                <div className="text-white text-xs italic mt-2">{t("screens.whyTabbby.footnote3")}</div>
          </div>

      </Screen>
    )
}