import LogoFooter from "components/icons/LogoFooter"
import Screen from "components/Screen"
import { useTranslation } from "react-i18next"

export default() => {
    const {t} = useTranslation()

    return (
        <Screen paddingOverwrite={"md:pt-20 md:pb-10 py-5 px-4"} backgroundType="white">
            <div className="mx-auto bg-white relative pt-10 md:pt-0">
                <div className="absolute md:-top-32 -top-16">
                    <LogoFooter/>
                </div>
                <div className="grid md:grid-cols-2 md:gap-20">
                    <div>
                        <h2 className="font-heading text-3xl mb-5">{t("screens.contact.header")}</h2>
                        <p>{t("screens.contact.intro")}</p>
                    </div>
                </div>
            </div>
        </Screen>
    )
}