import CreateIcon from "components/icons/CreateIcon"
import DepositIcon from "components/icons/DepositIcon"
import PayIcon from "components/icons/PayIcon"
import RepeatIcon from "components/icons/RepeatIcon"
import ShareIcon from "components/icons/ShareIcon"

interface InstructionsItemProps {
    url: string
    title: string
    children: string
}

export default ({url, title, children}: InstructionsItemProps) => {

    const getIcon = (name: string) => {
        switch(name){
            case 'create':
                return <CreateIcon />
            case 'share': 
                return <ShareIcon />
            case 'deposit':
                return <DepositIcon />
            case 'repeat':
                return <RepeatIcon />
            default:
                return <PayIcon />
        }
    }

    return (
        <div className="min-h-full bg-white p-8 mx-4 md:mx-0 rounded-2xl shadow">
            <div className="flex justify-center items-center mb-5">
                {getIcon(url)}
            </div>
            <div className="text-tertiary text-lg mb-2.5 font-bold text-left">{title}</div>
            <p className='mb-0 text-left' dangerouslySetInnerHTML={ {__html:children} }>
                {/* {children} */}
            </p>
        </div>
    )
}