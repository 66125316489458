import { RouteComponentProps, Route } from 'react-router-dom'

export interface Props extends RouteComponentProps<{
	hash: string
}> { }

export default (props: Props) => {

	const redirect = () => {
		window.location.href = `tabbby://Share/${props.match.params.hash}`

		setTimeout(() => {
			window.location.href = 'https://play.google.com/store/apps/details?id=com.facebook.katana&hl=en'
		}, 2500)

		return <div>Redirect Page</div>
	}

	return (
		<Route path='/' component={redirect} />
	)
}	