import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

export interface Props extends RouteComponentProps<{
	hash: string
}> { }

export default (props: Props) => {

	useEffect(() => {
		window.location.href = `tabbby://`
	}, [])

	return (
		<div>Your deposit has been executed successfully.</div>
	)
}