import * as Yup from 'yup'

Yup.setLocale({
	mixed: {
        required: ({path}: any) => `${translate(path)} is een verplicht veld.`
    },
	string: {
		min: ({ path, min }: any) => `${translate(path)} moet minimaal ${min} karakters lang zijn.`,
		email: ({ value }: any) => `'${value}' is geen geldig e-mail adres.`
	}
})

export default Yup.object().shape({
	name: Yup.string()
		.required(),
	company: Yup.string()
		.min(2)
		.required(),
	email: Yup.string()
		.email()
		.required()
})

//TODO: add I18N locale to this
const translate =(path: string) => {
	switch(path.toLowerCase()){
		case 'name': 
			return 'Naam'
		case 'company':
			return 'Bedrijf'
		case 'email':
			return 'E-mailadres'
		default:
			return ''
	}
}