type Logo = 'tabbby' | 'wiebetaaltwat' | 'tikkie' | 'tricount' | 'splitwise' | 'potje' | 'splitt'

interface Props {
    title: string
    logo: Logo
}

export default ({title, logo}: Props) => {

    let url: string;
    switch (logo) {
        case 'wiebetaaltwat':
            url = '/images/logos/wiebetaaltwat.png'
            break;
        case 'splitwise':
                url = '/images/logos/Logo_Splitwise.png'
            break;
        case 'tikkie':
                url = '/images/logos/Logo_Tikkie.png'
            break;
        case 'tricount':
                url = '/images/logos/Logo_Tricount.png'
            break;
        case 'potje':
                url = '/images/logos/Logo_Potje.png'
            break;
        case 'splitt':
                url = '/images/logos/Logo_Splitt.png'
            break;
        default:
            url = '/images/logos/Tabbby_Square_Blue.svg'
    }

    return (
        <div className="flex">
            {logo == 'tabbby' ? <img src={url} className="max-h-6 max-w-6"/> : null}
            <span className="ml-3 text-secondary font-bold">{title}</span>
        </div>
    )
}