interface SubHeadingProps {
    children: string,
    inverted?: boolean
    className? : string
}

export default ({ children, inverted = false, className}: SubHeadingProps) => {
    return (
        <h3 className={`${className || ''} font-heading text-2xl mt-4 mb-4 ${inverted ? 'text-white': ''}`}>{children}</h3>
    )
}