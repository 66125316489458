import Footer from 'components/Footer'
import Growler from 'components/Growler'
import Navigation from 'components/navigation/Navigation'

import AboutTabbby from './components/AboutTabbby'
import AboutUs from './components/AboutUs'
import ContactModal from './components/ContactModal'
import ContactUs from './components/ContactUs'
import DemoModal from './components/DemoModal'
import Development from './components/Development'
import HowDoesItWork from './components/HowDoesItWork'
import PrototypeModal from './components/PrototypeModal'
import WhyTabbby from './components/WhyTabbby'

export default () => {
    return (
        <>
            <div className="flex-auto text-secondary" style={{"backgroundColor": "#F6F6F9"}}>
                <Navigation />
                <Growler />
                <AboutTabbby />
                <HowDoesItWork />
                <WhyTabbby />
                <Development />
                <AboutUs />
                <ContactUs />
                <PrototypeModal />
                <DemoModal />
                <ContactModal />
            </div>
            <Footer />
      </>
    )
}