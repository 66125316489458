import Modal from "components/Modal"
import { useStateValue } from 'store/GlobalState'
import { ShowPrototypeModal } from 'store/GlobalActions'
import { useTranslation } from "react-i18next"

export default () => {
    const {i18n} = useTranslation()

    const [{showPrototypeModal}, dispatch] = useStateValue()
    
    const hideModal = () => dispatch(ShowPrototypeModal(false))

    const getUrl = () => {
        return (i18n.language === 'en') ? 
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FvQiPCrX9PYI7iMwt2UuRjf%2FTabbby.me%3Fpage-id%3D3419%253A1326%26node-id%3D6142%253A4865%26viewport%3D241%252C48%252C0.25%26scaling%3Dmin-zoom%26starting-point-node-id%3D4459%253A4193"
        :
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FvQiPCrX9PYI7iMwt2UuRjf%2FTabbby.me%3Fpage-id%3D3419%253A1326%26node-id%3D4459%253A4193%26viewport%3D241%252C48%252C0.25%26scaling%3Dmin-zoom%26starting-point-node-id%3D4459%253A4193"
    }
    return (
        <Modal show={showPrototypeModal} setShow={hideModal}>
            <div className="bg-white p-1 rounded shadow">
                <iframe className="w-full h-prototype-xs md:h-prototype md:w-prototype"  src={getUrl()}></iframe>
            </div>
        </Modal>
    )
}