import { useEffect, useState } from "react"
import DemoButton from "./buttons/DemoButton"

export default () => {
    const copyright = (new Date(Date.now())).getFullYear()

    const isMobile = window.innerWidth < 768
    
    const offset = isMobile? 64 : 85

    const [dimension, setDimension] = useState<number>(-offset)

    const item = document.getElementById("root")

    const handleScroll = (e: Event) => {
        const positionY = item?.scrollTop || 0
        setDimension(positionY < offset ? -(offset - positionY): 0)
    }

    useEffect(() => {
        if(isMobile) return

        item?.addEventListener('scroll', handleScroll)
        return () => {
            item?.removeEventListener('scroll', handleScroll)
        }
      })
    
    return (
        <div className="z-20 bg-white border-gray-100 sticky shadow" style={{bottom: dimension}}>
            <div className="container  bg-white p-4 flex justify-between mx-auto flex-col md:flex-row" >
                <div className="justify-self-start">
                    <DemoButton className='w-full'/>
                </div>
                <div className="justify-self-end self-center mt-4 md:mt-0 text-secondary text-center md:text-left flex flex-col md:flex-row">
                    <span>© {copyright} Tabbby/Inhouse Media</span><span>&nbsp;<span className="hidden md:inline">|</span> <a href="mailto:contact@tabbby.me?subject=Contact Tabbby - Lookbook">contact@tabbby.me</a></span>
                </div>
            </div>
        </div>
    )
}