import * as fromTypes from 'store/GlobalActionTypes'
import { GrowlerState } from './GlobalStateModel'

export const IsLoading = (payload: boolean): fromTypes.GlobalActionType => (
	{
		type: fromTypes.LOADING,
		payload: payload
	}
)

export const Refresh = (): fromTypes.GlobalActionType => (
	{
		type: fromTypes.REFRESH
	}
)

export const ShowContactModal = (payload: boolean): fromTypes.GlobalActionType => (
	{
		type: fromTypes.CONTACT_MODEL,
		payload: payload
	}
)


export const ShowDemoModal = (payload: boolean): fromTypes.GlobalActionType => (
	{
		type: fromTypes.DEMO_MODEL,
		payload: payload
	}
)

export const ShowPrototypeModal = (payload: boolean): fromTypes.GlobalActionType => (
	{
		type: fromTypes.PROTOTYPE_MODEL,
		payload: payload
	}
)

export const ShowGrowler = (payload: GrowlerState): fromTypes.GlobalActionType => (
	{
		type: fromTypes.GROWLER,
		payload: payload
	}
)